import React, { useContext, useState } from "react"
import { UserContext } from "../../context/UserContext"

export const UsersAddModal = () => {
    const { isOpenRegistrationModal, openRegistrationModal, registrationAuth } =
        useContext(UserContext)
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [phone, setPhone] = useState("")

    const registrationNewRieltor = () => {
        if (login && password && name && surname && phone) {
            registrationAuth({
                login,
                password,
                name,
                surname,
                phone,
            })
            return
        }
        alert("Заполните все поля для регистрации нового риелтора")
    }

    return (
        <>
            {" "}
            {isOpenRegistrationModal && (
                <div className="login add">
                    <div  className="add__body">

                   
                    <div className="login_item">
                        <h4 className="login__text">
                            Логин{" "}
                            {Boolean(login) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        <input
                            type="text"
                            className="login__input"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </div>
                    <div className="login_item">
                        <h4 className="login__text">
                            Пароль{" "}
                            {Boolean(password) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        <input
                            type="password"
                            className="login__input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="login_item">
                        <h4 className="login__text">
                            Имя{" "}
                            {Boolean(name) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        <input
                            type="text"
                            className="login__input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className="login_item">
                        <h4 className="login__text">
                            Фамилия{" "}
                            {Boolean(surname) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        <input
                            type="text"
                            className="login__input"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </div>

                    <div className="login_item">
                        <h4 className="login__text">
                            Телефон{" "}
                            {Boolean(surname) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        <input
                            type="text"
                            className="login__input"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className="login__nav">
                        <button
                            className="login__nav--delete"
                            onClick={() => {
                                if (isOpenRegistrationModal) {
                                    openRegistrationModal()
                                }
                            }}
                        >
                            Отменить
                        </button>
                        <button
                            className="login__nav--add"
                            onClick={registrationNewRieltor}
                        >
                            Зарегистировать
                        </button>
                    </div>
                    </div>
                </div>
            )}
        </>
    )
}
