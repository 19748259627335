import {
    createContext,
    useState,
    useEffect,
    type ReactNode,
    useContext,
} from "react"
import jwt_decode from "jwt-decode"
import { $authApi } from "../api/api"
import {
    DecodedToken,
    PayloadRegistrationUser,
    ROLE,
    UserType,
} from "../types/types"
import { AppContext } from "./AppContext"
import { AxiosResponse } from "axios"

const AuthContext = createContext<{
    userId:string
    isAuth: string
    roleUser: ROLE
    isOpenAuthModal: boolean
   
    openAuthModal: () => void

    setIsAuth: (a: string) => void
    loginAuth: ({
        login,
        password,
    }: {
        login: string
        password: string
    }) => Promise<void>
    logoutAuth: () => void

}>({
    userId: '',
    isAuth: localStorage.getItem("token") || "",
    roleUser: ROLE.USER,
    isOpenAuthModal: false,
    openAuthModal: () => {},

    setIsAuth() {},
    loginAuth() {
        return Promise.resolve()
    },
    logoutAuth() {},

})

const AuthContextProvider = ({ children }: { children: ReactNode }) => {

    const [userId, setUserId] = useState('')
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false)
    const [isAuth, setIsAuth] = useState(localStorage.getItem("token") || "")
    const [roleUser, setRoleUser] = useState(ROLE.USER)
    const { setLoader } = useContext(AppContext)

    useEffect(() => {
        const token: string | null = localStorage.getItem("token")
        if (token) {
            const decodedToken: DecodedToken = jwt_decode(token)
            const currentTime = Date.now() / 1000
            if (decodedToken.exp < currentTime) {
                setIsAuth("")
            }
            if (decodedToken?.role) {
                setRoleUser(decodedToken?.role)
            }
            if (decodedToken?._id) {
                setUserId(decodedToken?._id)
            }
  
        }
        localStorage.setItem("token", isAuth)
    }, [isAuth])


    const loginAuth = async ({
        login,
        password,
    }: {
        login: string
        password: string
    }) => {
        setLoader(true)
        $authApi
            .post("login", { login, password })
            .then((m) => {
                
                localStorage.setItem("token", m.data)
                setIsAuth(m.data)
                setLoader(false)
                alert('Вход успешно выполнен')
            })
            .catch((error) => {
                setLoader(false)
                alert(error?.response?.data?.message || "Error occurred login")
            })
    }

    const logoutAuth = () => {
        setLoader(true)
        $authApi
            .post("logout", { token: localStorage.getItem("token") })
            .then(() => {
                setIsAuth("")
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                alert(
                    error?.response?.data?.message ||
                        "Error occurred logoutAuth"
                )
            })
    }

    const openAuthModal = () => {
        setIsOpenAuthModal((s) => !s)
    }




    return (
        <AuthContext.Provider
            value={{
      
                userId,
                roleUser,
                isOpenAuthModal,
                openAuthModal,
                isAuth,
                setIsAuth,
                loginAuth,
                logoutAuth,
          
        
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContextProvider, AuthContext }
