import { useLocation, useNavigate } from "react-router-dom"
import "../../../style/main/properties.scss"
import { useContext, useEffect, useState } from "react"
import { DataContext } from "../../../context/DataContext"
import ModalAdd from "./ModalAdd"
import { PostsTypeTag } from "../../../types/types"
import { REALTY } from "../../../types/enum"
import PropertyList from "./PropertyList"
import { firstUpperLetter } from "../../../utils/methods"
import { AuthContext } from "../../../context/AuthContext"
import { useTranslation } from "react-i18next"

const Property = ({
    postTag,
    search,
}: {
    postTag: PostsTypeTag
    search?: string
}) => {

    const { listPost, isTag } = useContext(DataContext)
    const { t } = useTranslation()
    const [listPostState, setListPostState] = useState(listPost)
    const location = useLocation()

    useEffect(() => {
        if (search) {
       
            setListPostState(
             listPost.filter((post) => {
                    const { phone, city, description } = post
                    const searchTerms =
                        `${phone} ${city} ${description}`.toLowerCase()

                    return searchTerms.includes(search.toLowerCase())
                })
               
            )
        }
        else{
            setListPostState(listPost)
        }
    }, [search, listPost])

    return (
        <>
          
            {(postTag === REALTY.RENT && isTag.rent) ||
            (postTag === REALTY.SALE && isTag.sale) ||
            (postTag === REALTY.OWN_OBJECT && isTag.own) ? (
                <div className="properties" id={firstUpperLetter(postTag)}>
                    <div className="wraper--content properties__wrap">
                            <h4 className="route--name" style={{opacity: location.pathname.includes('photos-own') ? 0 : 1}}>
                           <>{t(`properties.${postTag}`)}</>
                        </h4>
                        
                        
                        <div className="properties__list">
                            <PropertyList
                                postTag={postTag}
                                list={listPostState.filter(
                                    (it) => it.tag === postTag
                                )}
                            />
                        </div>
                    </div>
                    <div
                        className="bg--content"
                        style={{
                            background:
                                REALTY.RENT === postTag ? "#1A1F19" : "inherit",
                        }}
                    ></div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default Property
