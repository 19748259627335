export enum LANGUAGE {
    EN = "en",
    RU = "ru",
    BG = "bg",
    RO = "ro",
}

export enum REALTY {
    SALE='sale',
    RENT='rent',
    OWN_OBJECT='own',
}
