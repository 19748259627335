const Messanger = () => {
    return (
        <>
            <a href='https://instagram.com/vm_estate_agency?igshid=YmMyMTA2M2Y=' target='_blank' rel='noreferrer'>
                instagram
            </a>
            <a href='https://www.facebook.com/profile.php?id=100091716826651' target='_blank' rel='noreferrer'>
                facebook
            </a>
        </>
    )
}

export default Messanger