import { REALTY, LANGUAGE } from "./enum"

export type PostsTypeTag = REALTY.RENT | REALTY.SALE | REALTY.OWN_OBJECT

export type LanguageType = LANGUAGE.EN | LANGUAGE.BG | LANGUAGE.RO | LANGUAGE.RU

export type RequiredPostFileds = {
    area: string
    district: string
    city: string
    price: string | number
}

export type AdditionalFieldType = { label: string; value: string }

export interface PostsInterface extends RequiredPostFileds {
    _id: string
    additionalFields?: string
    images: string[]
    bgFolderImages: string
    tag: PostsTypeTag
    description: string;
    phone: string;
    userIdCreatorData?: string | {
        login?: string,
        phone?: string,
        name?: string,
        surname?: string,
    },
}

export type CommentsType = {
    _id: string
    name: string
    comment: string
}

export enum ROLE {
    ADMIN = 'admin',
    RIELTOR = 'RIELTOR',
    USER = 'user',
}
export type DeviceType = "pc" | "mob"
export type DecodedToken = {
    exp: number
    role: ROLE
    _id: string
}

export interface User {

    login: string
    name: string
    surname: string
    phone: string
}

export interface UserType extends User {
    _id: string
}

export interface PayloadRegistrationUser extends User {
    password: string
}