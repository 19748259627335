import { PostsInterface, PostsTypeTag } from "../../../types/types"
import { useLocation, useNavigate } from "react-router-dom"
import { HREF } from "../../../utils/constants"
import { AuthContext } from "../../../context/AuthContext"
import { useContext, useState } from "react"
import { DataContext } from "../../../context/DataContext"
import { REALTY } from "../../../types/enum"
import { UserIcon } from "../../svg/UserIcon"
import ModalAdd from "./ModalAdd"

const PropertyList = ({
    list,
    postTag,
}: {
    list: PostsInterface[]
    postTag: PostsTypeTag
}) => {

    const [idObject, setIdObject]  = useState('')
    const [isOpenAddModal, setOpenAddModal ] = useState(false)
    const navigate = useNavigate()
    const { isAuth } = useContext(AuthContext)
    const { deletePost, changeTagPost } = useContext(DataContext)
    const location = useLocation()

    const navigateToSlider = (id: string) => {
        navigate(`/photos-${postTag}/${id}`)
    }

    const handlerDeletePost = (id: string) => {
        deletePost(id)
    }

    const handlerChangePostTag = (id: string, tag: PostsTypeTag) => {
        alert(`Вы действительно хотите переместить в ${tag}? `)
        changeTagPost(id, tag)
    }

    const changeObject = (_id:string) => {
        setIdObject(_id)
        setOpenAddModal(true)
    }

    const closeAddModal = (s:boolean) => {
        setIdObject('')
        setOpenAddModal(s)
    }

    return (
        <>
            {list.map((it, index) => (
                <div className="properties__list-item post__delete" key={index}>
                    {isAuth &&
                    it?.userIdCreatorData &&
                    typeof it?.userIdCreatorData !== "string" ? (
                        <div className="own-list-item own-list-item-small">
                            <div className="own-list-icon">
                                <UserIcon />
                            </div>
                            <div className="own-list-item-info">
                                <h5>
                                    {it?.userIdCreatorData?.name}{" "}
                                    {it?.userIdCreatorData?.surname}
                                </h5>
                                <h6>{it?.userIdCreatorData?.phone}</h6>
                            </div>
                            <div className="own-list-item-info"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <img
                        src={`${HREF}uploads/${it.bgFolderImages}`}
                        className="style--image"
                        onClick={() => navigateToSlider(it._id)}
                    />
                    <div className="properties__list-item_info">
                        <span>{it.area}</span> - <span>{it.price}</span>
                        <h5>
                            {it.city} / {it.district}
                        </h5>
                        {isAuth && <span>Phone {it.phone}</span>}
                    </div>
                    <div className="properties__list-item_line" />
                    {isAuth && (
                        <div className="post__delete--button">
                            {location.pathname === "/photos-own" ? (
                                <>
                                    <button
                                        className="own-header-button"
                                        onClick={() =>
                                            handlerChangePostTag(
                                                it._id,
                                                REALTY.RENT
                                            )
                                        }
                                    >
                                        Изменить на аренду
                                    </button>
                                    <button
                                        className=" own-header-button"
                                        onClick={() =>
                                            handlerChangePostTag(
                                                it._id,
                                                REALTY.SALE
                                            )
                                        }
                                    >
                                        Изменить на продажу
                                    </button>
                                </>
                            ) : (
                                <button
                                    className="own-header-button"
                                    onClick={() =>
                                        handlerChangePostTag(
                                            it._id,
                                            REALTY.OWN_OBJECT
                                        )
                                    }
                                >
                                    Изменить на личные
                                </button>
                            )}
                            <button
                                className="own-header-button"
                                onClick={() => changeObject(it._id)}
                            >
                                Изменить обьект
                            </button>
                            <button
                                className="properties__add--but"
                                onClick={() => handlerDeletePost(it._id)}
                            >
                                Удалить обьект
                            </button>
                        </div>
                    )}
                </div>
            ))}
            <ModalAdd  isOpenAddModal={isOpenAddModal} changeOpenAddModal={ closeAddModal} _id={idObject}/>
        </>
    )
}

export default PropertyList
