import React, { useContext, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import { UserContext } from "../../context/UserContext"

export const UsersChangePassword = ({userRieltiId}:{userRieltiId:string}) => {
    const { isOpenChangeModal, openChangeModal, changePasswordAuth } =
        useContext(UserContext)



    const [password, setPassword] = useState("")

    const changePassword = () => {
        if (password) {
            changePasswordAuth({
                newPassword: password,
                userId: userRieltiId,
            })
            openChangeModal()
            return
        }
        alert("Заполните все поля для регистрации нового риелтора")
    }

    return (
        <>
            {isOpenChangeModal && (
                <div className="login add">
                    <div className="login_item">
                        <h4 className="login__text">
                            Новый пароль
                            {Boolean(password) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        <input
                            type="text"
                            className="login__input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="login__nav">
                        <button
                            className="login__nav--delete"
                            onClick={() => {
                                if (isOpenChangeModal) {
                                    openChangeModal()
                                }
                            }}
                        >
                            Отменить
                        </button>
                        <button
                            className="login__nav--add"
                            onClick={changePassword}
                        >
                            Зарегистировать
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
