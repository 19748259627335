import React from 'react'
import '../../style/loader.scss'
const Loader = () => {
  return (
    <div className="loader__wrap">
        <span className="loader"> </span>
    </div>
    
  )
}

export default Loader