import {
    createContext,
    useState,
    useEffect,
    type ReactNode,
    useContext,
} from "react"
import jwt_decode from "jwt-decode"
import { $api, $authApi } from "../api/api"
import {
    DecodedToken,
    PayloadRegistrationUser,
    ROLE,
    UserType,
} from "../types/types"
import { AppContext } from "./AppContext"
import { AxiosResponse } from "axios"
import { AuthContext } from "./AuthContext"

const UserContext = createContext<{

    isOpenRegistrationModal: boolean
    openRegistrationModal: () => void
    registrationAuth: (s:PayloadRegistrationUser) => void,
    usersList: UserType[]
    deleteUser: (s:{_id:string}) => void

    isOpenChangeModal: boolean
    openChangeModal:() => void
    changePasswordAuth:(s:{userId:string, newPassword:string}) => void
}>({

    isOpenRegistrationModal:false,
    registrationAuth() {},
    openRegistrationModal() {},
    usersList:[],
    deleteUser() {},

    isOpenChangeModal: false,
    openChangeModal(){},
    changePasswordAuth(){},
})

const UserContextProvider = ({ children }: { children: ReactNode }) => {

    const [isOpenChangeModal, setIsOpenChangeModal] = useState(false)

    const [usersList, setUserList] = useState<UserType[]>([])
    const [isOpenRegistrationModal, setIsOpenRegistrationModal] = useState(false)

    const { setLoader } = useContext(AppContext)
    const { isAuth, roleUser } = useContext(AuthContext)

    useEffect(() => {
        
            if (isAuth && roleUser === ROLE.ADMIN) {
                $api
                    .get("user/get-all-user")
                    .then((res: AxiosResponse<UserType[]>) => {
                        setUserList(res.data)
                        setLoader(false)
                    })
                    .catch((error) => {
                        setLoader(false)
                        alert(
                            error?.response?.data?.message ||
                                "Bad response to get all user"
                        )
                    })
            }
        
    }, [isAuth, roleUser])

    const registrationAuth = async (payload: PayloadRegistrationUser) => {
        setLoader(true)
        $api
            .post("user/registration", payload )
            .then((res: AxiosResponse<UserType>) => {
                setUserList((s) => [...s, res.data])
                setLoader(false)
                setIsOpenRegistrationModal(false)
            })
            .catch((error) => {
                setLoader(false)
                alert(
                    error?.response?.data?.message ||
                        "Bad response to registration new rieltor"
                )
            })
    }

    const deleteUser = async ({_id}: { _id: string}) => {
        setLoader(true)
        $api
            .post("user/delete-user", {userId:_id } )
            .then((res: AxiosResponse<UserType>) => {
                setUserList((s) => s.filter(user => user._id !== _id))
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                alert(
                    error?.response?.data?.message ||
                        "Bad response to registration new rieltor"
                )
            })
    }


    const changePasswordAuth = (payload: {userId: string, newPassword:string}) => {
        setLoader(true)
        $api
            .post("user/change-password", {...payload } )
            .then((res: AxiosResponse<UserType>) => {
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                alert(
                    error?.response?.data?.message ||
                        "Bad response to registration new rieltor"
                )
            })
    }

   

    const openRegistrationModal = () => {
        setIsOpenRegistrationModal((s) => !s)
    }


    const openChangeModal = () => {
        setIsOpenChangeModal((s) => !s)
    }


    return (
        <UserContext.Provider
            value={{
                changePasswordAuth,
                isOpenChangeModal,
                openChangeModal,
                usersList,
                registrationAuth,
                isOpenRegistrationModal,
                openRegistrationModal,
                deleteUser
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export { UserContextProvider, UserContext }
