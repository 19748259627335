import { HREF } from "../../../utils/constants"

export const FilesListNames = ({
    filesNames,
    handleFileDeleteNames,
}: {
    filesNames: string[]
    handleFileDeleteNames: (i: number) => void
}) => {
    const deleteFile = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
        e.stopPropagation()
        handleFileDeleteNames(i)
    }

    return (
        <ul className="add__ul">
            {filesNames.map((file, index) => (
                <li key={file}>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={`${HREF}uploads/${file}`}
                            className="style--image"
                            style={{ width: "20px", height: "20px" }}
                        />
                        <span>
                            {`${file}/${file.length}`.length > 25
                                ? `${file}`.split("").slice(0, 25).join("") +
                                  "..."
                                : `${file}`}
                        </span>
                    </div>

                    <button
                        className="add__del"
                        onClick={(e) => {
                            deleteFile(e, index)
                        }}
                    >
                        Удалить
                    </button>
                </li>
            ))}
        </ul>
    )
}
