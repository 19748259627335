import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import { Search } from "../svg/Search"
import { UsersAddModal } from "./UsersAddModal"
import { UserContext } from "../../context/UserContext"
import { UserIcon } from "../svg/UserIcon"
import { UsersChangePassword } from "./UsersChangePassword"

export const Users = () => {
    const { isOpenChangeModal, usersList, openChangeModal, openRegistrationModal, deleteUser } =
        useContext(UserContext)
    const [search, setSearch] = useState("")

    const [userRieltiId, setUserId] = useState('')
    const [usersListState, setUserListState] = useState(usersList)

    const openModalChangePassword = (_id:string) => {
        if(!isOpenChangeModal){
            openChangeModal()
            setUserId(_id)
        }
        else{
            openChangeModal()
            setUserId('')
        }
    }


    useEffect(() => {
        if (search) {
            setUserListState(
                usersList.filter((user) => {
                    const { phone, name, surname, login } = user

               
                    
                    const searchTerms =
                        `${phone}${name}${surname}${login}`.toLowerCase()

                        
                    return searchTerms.includes(search.toLowerCase())
                })
            )
        }
        else{
            setUserListState(usersList)
        }
    }, [search, usersList])

    return (
        <div className="own">
            <div className="own-header">
                <div className="route--name">Команда риелторов</div>
                <div className="own-header-input">
                    <div className="own-search">
                        <input
                            type="text"
                            placeholder="Поиск риелтора по телефону, имени, фамили, логину например: Rieltor1"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div>
                            <Search />
                        </div>
                    </div>
                    <button
                        className="own-header-button"
                        onClick={openRegistrationModal}
                    >
                        Зарегистировать нового риелтора
                    </button>
                </div>
            </div>
            <div className="own-list">
                {usersListState.map((user) => (
                    <div className="own-list-item">
                        <div className="own-list-icon">
                            <UserIcon />
                        </div>
                        <div className="own-list-item-info">
                            <h5>
                                {user.name} {user.surname}
                            </h5>
                            <h6>{user.phone}</h6>
                        </div>
                        <div className="own-list-item-info">
                            <button onClick={() => openModalChangePassword(user._id)}>Изменить пароль </button>
                            <button
                                onClick={() => deleteUser({ _id: user._id })}
                            >
                                Удалить риелтора
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <UsersAddModal />
            <UsersChangePassword userRieltiId={userRieltiId}/>
        </div>
    )
}
