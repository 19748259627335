import { HREF } from "../../../utils/constants"

export const FilesList = ({
    files,
    handleFileDelete,
}: {
    files: File[]
    handleFileDelete: (i: number) => void
}) => {
    const deleteFile = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
        e.stopPropagation()
        handleFileDelete(i)
    }

    return (
        <ul className="add__ul">
            {Array.from(files).map((file, index) => (
                <li key={file.name}>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={URL.createObjectURL(file)}
                            className="style--image"
                            style={{ width: "20px", height: "20px" }}
                        />
                        <span>
                            {`${file.name}/${file.size}`.length > 25
                                ? `${file.name}`
                                      .split("")
                                      .slice(0, 25)
                                      .join("") + "..."
                                : `${file.name}`}
                        </span>
                    </div>
                    <button
                        className="add__del"
                        onClick={(e) => {
                            deleteFile(e, index)
                        }}
                    >
                        Удалить
                    </button>
                </li>
            ))}
        </ul>
    )
}
