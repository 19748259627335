import React, { useState, useContext, useRef, useEffect } from "react"
import { AdditionalFields } from "./AdditionalFields"
import { $api } from "../../../api/api"
import { FilesList } from "./FileList"
import { AdditionalFieldType, PostsInterface } from "../../../types/types"
import { AuthContext } from "../../../context/AuthContext"
import { AppContext } from "../../../context/AppContext"
import { RequiredFieldList } from "./RequiredFieldList"
import { FormatImages } from "../../../utils/constants"
import { REALTY } from "../../../types/enum"
import { DataContext } from "../../../context/DataContext"
import { FilesListNames } from "./FileListNames"

export const ModalAdd = ({
    isOpenAddModal,
    changeOpenAddModal,
    _id,
}: {
    _id?: string
    isOpenAddModal: boolean
    changeOpenAddModal: (s: boolean) => void
}) => {
    const { setLoader } = useContext(AppContext)
    const { setIsAuth, userId } = useContext(AuthContext)

    const { listPost } = useContext(DataContext)

    const [additionalFields, setAdditionalFields] = useState<
        AdditionalFieldType[]
    >([])

    const [description, setDescription] = useState("")
    const [bgFiles, setBgFiles] = useState<File[]>([])
    const [files, setFiles] = useState<File[]>([])

    const [bgFilesNames, setBgFilesNames] = useState<string[]>([])
    const [filesNames, setFilesNames] = useState<string[]>([])

    const [reqFields, setReqFields] = useState<Record<string, string>>({
        area: "",
        district: "",
        city: "",
        price: "",
        phone: "",
    })

    useEffect(() => {
        if (_id) {
            const currentObject: PostsInterface | undefined = listPost.find(
                (item) => item._id === _id
            )

            if (currentObject) {
                setReqFields({
                    area: currentObject.area,
                    district: currentObject.district,
                    city: currentObject.city,
                    price: String(currentObject.price),
                    phone: currentObject.phone,
                })
                setDescription(currentObject.description)
                setBgFilesNames([currentObject.bgFolderImages])
                setFilesNames(currentObject.images)
                if (currentObject?.additionalFields) {
                    const arrayAdditionalFields: AdditionalFieldType[] =
                        JSON.parse(currentObject?.additionalFields)
                    setAdditionalFields(arrayAdditionalFields)
                }
            }
        }
    }, [_id])

    const close = () => {
        if (isOpenAddModal) {
            changeOpenAddModal(false)
        }
    }

    const handleUpload = async (postTag?: REALTY) => {
        const formData = new FormData()

        if (_id) {
            formData.append("_id", _id)
            formData.append("bgFilesNames", bgFilesNames[0])
            formData.append("filesNames", JSON.stringify(filesNames))
        }

        formData.append("userIdCreator", userId)

        const currentTag = _id
            ? listPost?.find((item) => item._id === _id)?.tag
            : postTag

        formData.append("tag", currentTag || REALTY.OWN_OBJECT)

        formData.append("description", description)

        formData.append("additionalFields", JSON.stringify(additionalFields))

        for (const field in reqFields) {
            formData.append(field, reqFields[field])
        }

        let sizeFiles = 0
        formData.append("bgFolderImages", bgFiles?.[0] || [])
        sizeFiles = bgFiles?.[0]?.size

        for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i])
            sizeFiles += files[i].size
        }

        if (!files.length) {
            formData.append("images", JSON.stringify([]))
        }

        if (sizeFiles > 1900000) {
            alert("Большой размер изображений, максимальное 19Мб ")
            return
        }

        if (
            !(bgFiles.length + bgFilesNames.length) ||
            !(files.length + filesNames.length) ||
            !reqFields?.area.length ||
            !reqFields?.district.length ||
            !reqFields?.city.length ||
            !reqFields?.price.length
        ) {
            alert("Заполните все обязательние поля")
            return 0
        }

        try {
            setLoader(true)
            await $api.post("posts/add-posts", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            setLoader(false)
            alert(
                _id
                    ? "Успешно изменено, подтвердите"
                    : "Успешно загружено, подтвердите"
            )
            close()
            window.location.reload()
        } catch (error: any) {
            if (error?.response?.status === 401) {
                setIsAuth("")
            }
            setLoader(false)
            alert(error?.response?.data?.message || "Error occurred")
        }
    }

    const modalRef = useRef<HTMLDivElement>(null)

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFiles(e.target.files as unknown as File[])
    }

    const handleFileDelete = (i: number) => {
        setFiles(Array.from(files).filter((el, index) => index !== i))
    }

    const handleBgFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBgFiles(e.target.files as unknown as File[])
        setBgFilesNames([])
    }

    const handleBgFileDelete = (i: number) => {
        setBgFiles(Array.from(bgFiles).filter((el, index) => index !== i))
    }

    const handleFileDeleteNames = (i: number) => {
        setFilesNames(filesNames.filter((el, index) => index !== i))
    }

    const handleBgFileDeletNames = (i: number) => {
        setBgFilesNames(bgFilesNames.filter((el, index) => index !== i))
    }

    return (
        <>
            {isOpenAddModal && (
                <div className="login add modal" ref={modalRef}>
                    <h3 className="login__text">Добавить обьект</h3>

                    <RequiredFieldList
                        reqFields={reqFields}
                        setReqFields={(s) => setReqFields(s)}
                    />

                    <AdditionalFields
                        additionalFields={additionalFields}
                        setAdditionalFields={setAdditionalFields}
                    />

                    <div className="login_item">
                        <h4 className="login__text">Описание</h4>
                        <textarea
                            placeholder="Описание"
                            className="login__input login__input-desc"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                    <div className="login_item">
                        <h4 className="login__text">
                            Фото на заставку обьекта
                        </h4>
                        <label
                            htmlFor="file-bgupload"
                            className="login__nav--add add__label"
                        >
                            Загрузить заставку
                        </label>
                        <input
                            type="file"
                            accept={FormatImages}
                            id="file-bgupload"
                            onChange={(e) => {
                                handleBgFileUpload(e)
                            }}
                        />
                        <h4 className="login__text">
                            {FormatImages}
                            {Boolean(bgFiles.length + bgFilesNames.length) || (
                                <span className="login__text login__text-req ">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        {Boolean(bgFilesNames.length) && (
                            <FilesListNames
                                filesNames={bgFilesNames}
                                handleFileDeleteNames={handleBgFileDeletNames}
                            />
                        )}
                        {Boolean(bgFiles.length) && (
                            <FilesList
                                files={bgFiles}
                                handleFileDelete={handleBgFileDelete}
                            />
                        )}
                    </div>

                    <div className="login_item">
                        <h4 className="login__text">Файлы обьекта</h4>
                        <label
                            htmlFor="file-upload"
                            className="login__nav--add add__label"
                        >
                            Загрузить файлы
                        </label>
                        <input
                            type="file"
                            multiple
                            accept={FormatImages}
                            id="file-upload"
                            onChange={(e) => {
                                handleFileUpload(e)
                            }}
                        />
                        <h4 className="login__text">
                            {FormatImages}
                            {Boolean(files.length + filesNames.length) || (
                                <span className="login__text login__text-req">
                                    Обязательное поле
                                </span>
                            )}
                        </h4>
                        {Boolean(filesNames.length) && (
                            <FilesListNames
                                filesNames={filesNames}
                                handleFileDeleteNames={handleFileDeleteNames}
                            />
                        )}
                        {Boolean(files.length) && (
                            <FilesList
                                files={files}
                                handleFileDelete={handleFileDelete}
                            />
                        )}
                    </div>
                    <div className="login__nav">
                        {_id ? (
                            <button
                                className="login__nav--add"
                                onClick={() => handleUpload()}
                            >
                                Обновить
                            </button>
                        ) : (
                            <>
                                <button
                                    className="login__nav--add"
                                    onClick={() => handleUpload(REALTY.RENT)}
                                >
                                    Добавить в аренду
                                </button>
                                <button
                                    className="login__nav--add"
                                    onClick={() => handleUpload(REALTY.SALE)}
                                >
                                    Добавить в продажу
                                </button>

                                <button
                                    className="login__nav--add"
                                    onClick={() =>
                                        handleUpload(REALTY.OWN_OBJECT)
                                    }
                                >
                                    Добавить в личные
                                </button>
                            </>
                        )}

                        <button className="login__nav--delete" onClick={close}>
                            Выйти
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default ModalAdd
