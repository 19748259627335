import Property from "../main/property/Property"
import { REALTY } from "../../types/enum"
import { useContext, useState } from "react"
import { Search } from "../svg/Search"
import { DataContext } from "../../context/DataContext"
import ModalAdd from "../main/property/ModalAdd"

const OwnObjects = () => {
    const [search, setSearch] = useState("")
    const [isOpenAddModal, setOpenAddModal ] = useState(false)

    return (
        <>
        <ModalAdd  isOpenAddModal={isOpenAddModal} changeOpenAddModal={ setOpenAddModal}/>
        <div className="own">
            <div className="own-header">
                <div className="route--name">
                    Личные обьекты
                </div>
                <div className="own-header-input">
                    <div className="own-search">
                        <input
                            type="text"
                            placeholder="Поиск обьекта по телефону, городу, району например: 029459140"
                            value={search}
                            onChange={e=>setSearch(e.target.value)}
                        />
                        <div>
                            <Search />
                        </div>
                    </div>
                    <button className="own-header-button" onClick={() => setOpenAddModal(true)}>Добавить новый обьект</button>
                </div>
            </div>

            <Property postTag={REALTY.OWN_OBJECT} search={search} />
        </div>
        </>
    )
}

export default OwnObjects
